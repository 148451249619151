export default {
  colormounted(color) {
    if (color == undefined) {
      var color = "#f5f5f5";
    }
    document
      .querySelector("body")
      .setAttribute("style", "background-color:" + color);
  },
};
