<template>
  <div>
    <div class="headerclass">
      <div class="center flx">
        <div class="flx1" style="margin-top: 0.2rem">
          <div>
            <van-icon
              @click="back"
              v-if="userLocation.iconshow == true"
              name="arrow-left"
              size="0.5rem"
            />
          </div>
          <div
            class="functionleft"
            @click="cancel"
            v-if="userLocation.iconshow == false"
          >
            取消
          </div>
        </div>
        <div class="textname">{{ userLocation.hradertext }}</div>
        <div
          class="functionright"
          @click="administration"
          v-if="userLocation.rightshow == true"
        >
          {{ userLocation.rightext }}
        </div>
      </div>
    </div>
    <div class="texttop"></div>
  </div>
</template>
<script>
  import { ref, inject } from "vue";
  export default {
    emits: ["administration", "cancel"],
    // context() 定义传值的方法
    setup(props, context) {
      // inject('获取父级传的参数')
      // 定义并使用
      const userLocation = inject("hradertext");
      function back() {
        console.log(history, "历史");
        if (history.state.current == "/Menu") {
          uni.postMessage({
            data: {
              value: history.state.current,
              skipurl: true,
            },
          });
        }
      }
      function administration() {
        context.emit("administration", true);
      }
      function cancel() {
        context.emit("cancel");
      }
      return {
        back,
        userLocation,
        administration,
        cancel,
      };
    },
  };
</script>
<style lang="scss" scoped>
  .headerclass {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background-color: #fff;
    height: 1.5rem;
    overflow: hidden;
    .center {
      width: 90%;
      margin: 0 auto;
    }
    .textname {
      font-size: 0.4rem;
      line-height: 1.8rem;
    }
    .functionright {
      font-size: 0.5rem;
    }
    .functionleft {
      height: 1.6rem;
      font-size: 0.3rem;
      color: rgb(77, 89, 247);
      line-height: 1.4rem;
    }
    .functionright {
      font-size: 0.3rem;
      line-height: 1.8rem;
      color: rgb(77, 89, 247);
    }
  }
  .texttop {
    height: 1.5rem;
    width: 100%;
  }
</style>
