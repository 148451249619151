<template>
  <div>
    <navbacr @administration="administration" @cancel="cancel" />
    <div class="child" />
    <!-- 常用菜单列表块 -->
    <div class="list">
      <van-grid square :border="false">
        <div class="headline">快捷菜单</div>
        <van-grid-item
          @touchstart="gotouchstart"
          @touchend="emptyTime"
          v-for="(item, index) in shortcut"
          :key="index"
        >
          <van-icon name="logistics" class="badge-icon" size="0.8rem" />
          <text style="font-size: 0.26rem; margin-top: 0.2rem">
            {{ item.menuName }}
          </text>
          <div
            class="kjgadge"
            v-if="item.Iconshow == true"
            @click="cancelMenu(index)"
          >
            <van-icon name="minus" class="iconstyle" size="0.24rem" />
          </div>
        </van-grid-item>
        <div v-if="shortcut.length == 0" class="flx2 commonstyle">
          <van-empty
            image-size="50px"
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无快捷菜单"
          />
        </div>
      </van-grid>
    </div>
    <!-- 全部菜单列表 -->
    <div class="list" v-for="(item, index) in Commonlist" :key="index">
      <van-grid square :border="false">
        <div class="headline">{{ item.menuName }}</div>
        <van-grid-item
          @touchstart="gotouchstart"
          @touchend="emptyTime"
          v-for="(childrenitem, indexkey) in item.children"
          :key="indexkey"
        >
          <van-icon name="success" class="badge-icon" size="0.8rem" />
          <text style="font-size: 0.26rem; margin-top: 0.1rem">
            {{ childrenitem.menuName }}
          </text>
          <div
            class="qbgadge"
            v-if="childrenitem.Iconshow == true"
            @click="addmenu(childrenitem)"
          >
            <van-icon name="plus" class="iconstyle" size="0.24rem" />
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
  import navbacr from "@/components/componentheader/componentheader";
  import color from "@/components/componentsJs/color";
  import {
    ref,
    getCurrentInstance,
    onMounted,
    onUpdated,
    provide,
    reactive,
    watch,
  } from "vue";
  import { Toast, Dialog } from "vant";
  export default {
    components: {
      navbacr,
    },
    data() {
      return {};
    },
    setup() {
      let Menuheader = ref({
        hradertext: "应用中心",
        iconshow: true,
        rightshow: true,
        rightext: "管理",
      });

      // 父向子传值provide('传值的名称'，需要传的参数)
      provide("hradertext", Menuheader);
      // vue 调用main.js 执行全局函数
      const { proxy } = getCurrentInstance();
      color.colormounted();
      // 定义空值用于储存时间方法（用于长按唤醒）
      const Loop = ref();
      // 全部列表定义使用 Commonlist
      const Commonlist = ref([]);
      // 常用菜单列表
      const shortcut = ref([]);
      // 添加的菜单数组方法
      const addMenuArray = ref("");

      // 长按方法（长按显示添加、删除）
      function gotouchstart() {
        Loop.value = setTimeout(
          function () {
            Commonlist.value.forEach((item) => {
              item.children.forEach((items) => {
                items.Iconshow = true;
              });
            });
            shortcut.value.forEach((item) => {
              item.Iconshow = true;
            });
            Menuheader.value = {
              hradertext: "应用中心",
              iconshow: false,
              rightshow: true,
              rightext: "保存",
            };
            console.log(Menuheader);
          }.bind(this),
          1000
        );
      }
      // 长按结束，触摸结束清空时间方法
      function emptyTime() {
        clearTimeout(Loop.value);
      }
      // 添加菜单
      function addmenu(item) {
        // 定义一个状态
        let repetition = false;
        if (shortcut.value.length > 0) {
          // 判断快捷菜单是否存在
          shortcut.value.forEach((itemvalue) => {
            if (itemvalue.id == item.id) {
              repetition = true;
            }
          });
          // 循环所有菜单
          // Commonlist.value.forEach((afteritem) => {
          //   if (afteritem.children.length > 0) {
          //     afteritem.children.forEach((items) => {
          //       if (item.id == items.id) {
          //         console.log("jinru1");
          //         items.Iconshow = false;
          //       }
          //     });
          //   }
          // });

          if (repetition == false) {
            item.Iconshow = false;
            shortcut.value.push(item);
            shortcut.value[shortcut.value.length - 1].Iconshow = true;
            let stringnumber = String(item.id);
            if (addMenuArray.value == "") {
              let addstore = sessionStorage.getItem("addMenuArray");
              if (addstore != "") {
                addMenuArray.value = addstore;
              }
            }
            addMenuArray.value = addMenuArray.value + "," + stringnumber;
            console.log(addMenuArray.value);
          } else {
            Toast("不可重复添加");
          }
        } else {
          // item.Iconshow = true;
          shortcut.value.push(item);
          shortcut.value[shortcut.value.length - 1].Iconshow = true;
          let stringnumber = String(item.id);
          addMenuArray.value = stringnumber;
        }
      }
      // 取消菜单
      function cancelMenu(index) {
        console.log(index);
        shortcut.value.splice(index, 1);
        let addArrayId = addMenuArray.value.split(",");
        addArrayId.splice(index, 1);
        addMenuArray.value = addArrayId.toString();
        if (addArrayId.length != 0) {
          addMenuArray.value = addArrayId.toString();
        } else {
          addMenuArray.value = "";
        }
      }
      // 获取全部菜单经行处理
      const getUserMenus = async () => {
        let menlist = await proxy.$Api.get(proxy.$MenuApi.getUserMenus, "");
        menlist = menlist.data.data;
        menlist.forEach((item) => {
          if (item.children.length > 0) {
            item.children.forEach((items, index) => {
              console.log(items, "数据");
              if (items.mobileUse == 1) {
                items.Iconshow = false;
                items.MeuShow = true;
              } else {
                shortcut.value.splice(index, 1);
              }
            });
          }
        });
        Commonlist.value = menlist;
        // 判断是否请求获取快捷菜单接口，否则拿缓存
        let IfRefresh = sessionStorage.getItem("quickrRefresh");
        if (!sessionStorage.getItem("savefilstMenu")) {
          let usemenlist = await proxy.$Api.get(
            proxy.$MenuApi.userFastMenus,
            ""
          );
          usemenlist = usemenlist.data.data;
          console.log("dayin1", usemenlist);
          usemenlist.forEach((MensId) => {
            if (addMenuArray.value == "") {
              addMenuArray.value = MensId.menuId;
            } else {
              addMenuArray.value = addMenuArray.value + "," + MensId.menuId;
            }
          });
          Commonlist.value.forEach((item) => {
            if (item.children.length > 0) {
              item.children.forEach((items) => {
                usemenlist.forEach((useitem) => {
                  if (items.id == useitem.menuId) {
                    shortcut.value.push(items);
                  }
                });
              });
            }
          });
          sessionStorage.setItem("quickrRefresh", false);
        } else {
          // 获取储存的快捷菜单
          let savefilstMenu = sessionStorage.getItem("savefilstMenu");
          let saveArrayFirstMenu = JSON.parse(savefilstMenu);
          console.log("先保存菜单", saveArrayFirstMenu);
          shortcut.value = saveArrayFirstMenu;
          // 获取储存的快捷菜单id
          let savefirstId = sessionStorage.getItem("addMenuArray");
          addMenuArray.value = savefirstId;
        }
      };
      // 保存快捷菜单管理
      const saveCommonMenu = async () => {
        // 保存快捷菜单id
        addMenuArray.value == "";
        console.log(addMenuArray.value, "储存前的值");
        let savelist = await proxy.$Api.post(proxy.$MenuApi.saveCommonMenu, {
          ids: addMenuArray.value,
        });
        if ((savelist.data.msg = "操作成功")) {
          // 隐藏所有图标
          Commonlist.value.forEach((item) => {
            item.children.forEach((items) => {
              items.Iconshow = false;
            });
          });
          shortcut.value.forEach((item) => {
            item.Iconshow = false;
          });
          sessionStorage.setItem("addMenuArray", addMenuArray.value);
          addMenuArray.value = String(addMenuArray.value);
          let jsonfistMens = JSON.stringify(shortcut.value);
          // 储存快捷菜单
          sessionStorage.setItem("savefilstMenu", jsonfistMens);
          Toast("保存成功！");
        }
      };
      // 菜单管理保存
      function administration(e) {
        if (Menuheader.value.rightext == "管理") {
          console.log(e);
          Menuheader.value = {
            hradertext: "应用中心",
            iconshow: false,
            rightshow: true,
            rightext: "保存",
          };
          if (e == true) {
            Commonlist.value.forEach((item) => {
              item.children.forEach((items) => {
                items.Iconshow = true;
              });
            });
          }
        } else if (Menuheader.value.rightext == "保存") {
          saveCommonMenu();
        }
      }
      // 去选菜单管理
      function cancel(e) {
        Menuheader.value = {
          hradertext: "应用中心",
          iconshow: true,
          rightshow: true,
          rightext: "管理",
        };
        Dialog.confirm({
          title: "提示",
          message: "编辑的内容还未保存，是否要保存",
          "confirm-button-color": "#2979ff",
          confirmButtonText: "保存",
        })
          .then(() => {
            saveCommonMenu();
          })
          .catch(() => {
            let storeFirstList = JSON.parse(
              sessionStorage.getItem("savefilstMenu")
            );
            console.log(storeFirstList, "储存值");
            shortcut.value = storeFirstList;
            // 隐藏所有图标
            Commonlist.value.forEach((item) => {
              item.children.forEach((items) => {
                items.Iconshow = false;
              });
            });
            shortcut.value.forEach((item) => {
              item.Iconshow = false;
            });
          });
      }
      // watch(Menuheader, (newValue, oldValue) => {
      //   console.log(newValue, oldValue);
      // });
      onMounted(() => {
        getUserMenus();
      });
      // 返回数据
      return {
        gotouchstart,
        emptyTime,
        // 菜单列表
        Commonlist,
        // 快捷菜单
        shortcut,
        // 添加菜单
        addmenu,
        // 取消菜单
        cancelMenu,
        // 头部组件
        Menuheader,
        // 管理
        administration,
        Menuheader,
        // 取消
        cancel,
        // 菜单保存
        saveCommonMenu,
      };
    },
  };
</script>
<style lang="scss" scoped>
  // 公共继承样式
  .commonstyle {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  @mixin border-radius($radiusvalue) {
    border-radius: $radiusvalue;
  }
  // 定位
  @mixin position($top, $right) {
    position: absolute;
    top: $top;
    right: $right;
  }
  // div 背景色
  @mixin backgroundcolor($color) {
    background-color: $color;
  }
  // fontfarmirly字体大致配
  @mixin font($size, $line_height, $text, $color) {
    font-size: $size;
    line-height: $line_height;
    text-align: $text;
    color: $color;
  }
  // 常用用于底下样式继承使用
  .common {
    width: 90%;
    margin: 0 auto;
    height: auto;
  }
  // 封边，溢出隐藏
  .borderOver {
    box-sizing: border-box;
    overflow: hidden;
  }
  /*
  *页面样式
  *菜单列表样式
  */
  .list {
    @extend .common;
    @include border-radius(0.2rem);
    padding: 0.2rem;
    background-color: #fff;
    margin-top: 0.3rem;
  }
  .headline {
    width: 100%;
    font-size: 0.3rem;
    text-align: left;
  }
  .badge-icon {
    display: block;
    font-size: 10px;
    line-height: 16px;
  }
  .qbgadge {
    width: 0.3rem;
    height: 0.3rem;
    @include border-radius(50%);
    @include position(0, 0);
    @include font(0.3rem, 0.3rem, center, #fff);
    @extend .common;
    @extend .borderOver;
    background-color: #2979ff;
  }
  .kjgadge {
    @extend .qbgadge;
    background-color: #cecece;
  }
  .iconstyle {
    line-height: 0.3rem;
  }
  .custom-image .van-empty__image {
    width: 0.5rem;
    height: 0.5rem;
  }
</style>
